<template>
  <b-container class="pt-10">
    <b-row>
      <b-col class="d-flex align-items-center justify-content-center">
        <span
          class="spinner-border spinner-border-lg text-primary center-spinner"
          v-if="loading"
        ></span>
        <div class="b-alert b-alert-danger" v-if="error">{{ error }}</div>
      </b-col>
      <b-col v-if="$route.query.debug">
        <b-button @click="login">go</b-button>
        {{ url }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Amplify from 'aws-amplify'

export default {
  name: 'QuickLogin',
  components: {},
  data() {
    return {
      loading: false,
      error: null
    }
  },
  mounted() {
    if (!this.$route.query.debug) {
      this.login()
    }
  },
  computed: {
    organization() {
      return this.$route.params.organization
    },
    username() {
      return this.$route.params.username
    },
    password() {
      return this.$route.params.password
    },
    locale() {
      return this.$route.params.locale
    },
    redirectUrl() {
      return this.$route.params.redirect
    },
    url() {
      const organizationName = this.$route.params.organization
      const username = this.$route.params.username
      const temporaryPassword = this.$route.params.password
      const locale = this.$route.params.locale
      const redirect = this.$route.params.redirect
      const url = `/login?organization=${organizationName}&username=${username}&password=${temporaryPassword}&locale=${locale}&redirect=${redirect}&quicklogin=true`
      // eslint-disable-next-line no-console
      return url
    }
  },

  methods: {
    login() {
      this.loading = true
      localStorage.organizationName = this.organization
      Amplify.API.get('PublicAPI', '/organizations/' + this.organization, {})
        .then((orgResult) => {
          localStorage.orgData = JSON.stringify(orgResult)
          Amplify.configure({
            Auth: {
              identityPoolId: orgResult.identityPoolId,
              region: 'eu-west-1',
              userPoolId: orgResult.userPoolId,
              userPoolWebClientId: orgResult.userPoolWebClientId,
              mandatorySignIn: true,
              clientMetadata: {
                organizationName: this.organization,
                organizationFullName: orgResult.organizationFullName,
                locale: this.locale || 'en'
              }
            }
          })
        })
        .then(() => Amplify.Auth.signIn(this.username, this.password))
        .then((user) => {
          // eslint-disable-next-line no-console
          this.loading = false
          this.$store.commit('setLoggedInUser', user)
          this.$store.commit('setHasToken', true)
          if (this.redirectUrl) {
            window.location = decodeURIComponent(this.redirectUrl)
          } else {
            this.$router.push('/')
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.loading = false
          if (err.response && err.response.status === 404) {
            this.error = this.$t('error.OrganizationNotFoundException')
          } else if (err.code === 'NotAuthorizedException') {
            this.error = this.$t(`setup.error.invalid`)
          } else if (err.code === 'UserNotFoundException') {
            this.error = this.$t('error.UserNotFoundException', {
              username: this.username
            })
          } else {
            if (typeof err === 'string') {
              this.error = err
            } else {
              this.error = this.$t(`error.${err.code}`, {
                username: this.username
              })
            }
          }
        })
    },

    redirect() {
      const url = this.url
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped></style>
